<template>
  <div class="examType">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="select_Info">
        <a-button type="primary" @click="()=>this.addShow = true">新增</a-button>
      </div>
      <div style="background-color: rgba(240, 242, 245, 1);min-width: 100%;height: 10px">
      </div>
      <a-table :columns="columns" style="min-height: 500px;padding: 15px" :data-source="InfoTable" rowKey="id"   :pagination="false">
        <template slot="dailyPracticeNum" slot-scope="text, row, index">
          {{row.dailyPracticeNum}}
          <!--      <a @click="dpnClick(row)">{{row.dailyPracticeNum ? row.dailyPracticeNum : '未设置'}}</a>-->
        </template>
        <template slot-scope="text, row, index" slot="status">
          <a-switch @change="switchChange($event, row)" checked-children="开" :checked="row.isOpen === 1" un-checked-children="关" />
        </template>
        <template slot="banner" slot-scope="text, row, index">
          <a @click="bannerClick(row)">
            {{row.banner && row.banner.length > 0 ? '已设置' : '未设置'}}
          </a>
        </template>
        <template slot-scope="text, row, index" slot="examTime">
          <a @click="timeOpen($event,row)">{{row.examTime && moment(row.examTime).isValid() ? moment(row.examTime).format('YYYY-MM-DD') : '未设置'}}</a>
        </template>
        <template slot="openMode" slot-scope="text, row, index">
          <a @click="openModeClick(row)">设置</a>
        </template>
        <span slot="examType" slot-scope="text,item,index">
<!--            <span v-for="list in item.memberJobTitle">-->
          <!--            </span>-->
                    {{item.memberJobTitle.join('；')}}

            </span>
        <!--    <span slot="open" slot-scope="text,item,index">-->
        <!--              {{item.isOpen === 1 ? '开':'关'}}-->
        <!--            </span>-->
        <span slot="operate" slot-scope="text,item,index">
              <a style="margin-right: 10px" @click="editBtn(item.id,item, item)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
      </a-table>

      <div   style="display: flex;justify-content: space-between;margin: 10px">
                    <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
                        pagination.current
                      }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      showQuickJumper
                      showSizeChanger
                      @showSizeChange="paginationChange"
                      @change="paginationChange" />
      </div>
      <!--  </div>-->
    </a-spin>

    <a-modal :get-container="false" v-model="addShow" title="新增" @ok="addOk">
      <a-form-model  :rules="rules"  :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
        <a-form-model-item  label="名称" prop="name">
          <div style="display: flex">
            <a-input placeholder="输入分类名称" v-model="addForm.name"/>
            <a @click="openSample('examType_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </div>
        </a-form-model-item>
        <a-form-model-item  label="身份" prop="memberType">
          <a-select v-model="addForm.memberType"  placeholder="选择身份" >
            <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.label" @click="typeChange(list.value)">
              {{ list.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职称" prop="memberJobTitle">
          <a-select
              placeholder="选择职称"
              v-model="addForm.memberJobTitle"
              mode="multiple"
              :maxTagCount= 3
              showArrow
              :maxTagTextLength = 7
          >
            <a-select-option v-for="item in titleInfo" :key="item.id" :value="item.jobTitle">
              {{item.jobTitle}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="随机题目数" prop="dailyPracticeNum">
          <a-input-number placeholder="请输入" min="1" v-model="addForm.dailyPracticeNum"></a-input-number>
        </a-form-model-item>
        <!--      <a-form-model-item label="默认显示" prop="isOpen">-->
        <!--        <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />-->
        <!--      </a-form-model-item>-->
      </a-form-model>
    </a-modal>
    <a-modal :get-container="false" v-model="editShow" title="修改" @ok="editOk">
      <a-form-model  :rules="rules"  :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
        <a-form-model-item  label="名称" prop="name">
          <a-input placeholder="输入分类名称" v-model="editForm.name"/>

          <!--        <div style="display: flex">-->
          <!--          <a-input placeholder="输入分类名称" v-model="editForm.name"/>-->
          <!--          <a @click="openSample('examType_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>-->
          <!--        </div>-->
        </a-form-model-item>
        <a-form-model-item  label="身份" prop="memberType">
          <a-select v-model="editForm.memberType"  placeholder="选择身份" >
            <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.label" @click="typeChange(list.value)">
              {{ list.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职称" prop="memberJobTitle">
          <a-select
              placeholder="选择职称"
              v-model="editForm.memberJobTitle"
              mode="multiple"
              :maxTagCount= 3
              showArrow
              :maxTagTextLength = 7
          >
            <a-select-option v-for="item in titleInfo" :key="item.id" :value="item.jobTitle">
              {{item.jobTitle}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="随机题目数" prop="dailyPracticeNum">
          <a-input-number placeholder="请输入" min="1" v-model="editForm.dailyPracticeNum"></a-input-number>
        </a-form-model-item>
        <!--      <a-form-model-item label="默认显示" prop="isOpen" >-->
        <!--        <a-switch :checked="editForm.isOpen!==0"   @click="openEdit(editForm.isOpen === 1? 0 : 1)"-->
        <!--                  checked-children="是" un-checked-children="否"  />-->
        <!--      </a-form-model-item>-->
      </a-form-model>
    </a-modal>
    <a-modal :width="800" :get-container="false" v-model="openModeVlist" title="设置功能">
      <a-spin :spinning="modeSping">

      <a-table :pagination="false" :columns="modeColumns" style="min-height: 500px;padding: 15px" :data-source="modeTable">
        <template
            v-for="col in ['name']"
            :slot="col"
            slot-scope="text, record, index"
        >
          <div :key="col">
<!--            <a-input-->
<!--                v-if="record.editable"-->
<!--                style="margin: -5px 0"-->
<!--                :value="text"-->
<!--                @change="e => handleChange(e.target.value, record.id, col)"-->
<!--            />-->
            <a-select
                show-search
                @focus="getAllModeList(editForm.id)"
                placeholder="请选择"
                v-if="record.editable"
                style="width: 120px"
                @change="handleChange($event, record)">
              <a-select-option v-for="item in modeAllList" :value="item.id" :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="operate" slot-scope="text, record, index">
          <div class="editable-row-operations">
        <span v-if="record.editable">
          <a-popconfirm title="是否确定保存?" @confirm="() => save(record.id, record)">
            <a>保存</a>
          </a-popconfirm>
          <a-popconfirm title="是否确定取消?" @confirm="() => cancelE(record.id)">
            <a style="margin-left: 5px">取消</a>
          </a-popconfirm>
        </span>
            <span v-else>
          <a v-show="index !== 0" :disabled="editingKey !== ''" @click="() => modeUp(record.id, 'up')" style="margin-right:5px">上移</a>
          <a v-show="index !== modeTable.length - 1" :disabled="editingKey !== ''" @click="() => modeUp(record.id, 'down')" style="margin-right:5px">下移</a>
<!--          <a :disabled="editingKey !== ''" @click="() => edit(record.id)">编辑</a>-->
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delMode(record.id)"
          >
            <a :disabled="editingKey !== ''">删除</a>
          </a-popconfirm>
        </span>
          </div>
        </template>
      </a-table>
        <a-button style="width: 100%" type="dashed" @click="modeAddRow" :disabled="editingKey !== ''">+ 添加</a-button>
      </a-spin>
      <template slot="footer">
        <a-button @click="openModeVlist = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal :get-container="false" v-model="examTimeShow" title="考试时间">
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="timeRef">
        <a-form-model-item label="考试时间" prop="examTime">
          <a-date-picker :disabled-date="disabledDate" @change="examTimeChange" v-model="editForm.examTime" />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="()=> examTimeShow = false">取消</a-button>
        <a-popconfirm
            title="是否确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delTime"
        >
          <a-button>删除时间</a-button>
        </a-popconfirm>
        <a-button type="primary" @click="examTimeOk">确定</a-button>
      </template>
    </a-modal>
    <a-modal :get-container="false" v-model="bannerShow" title="Banner图管理">
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="bannerRef">
        <a-form-model-item label="Banner" prop="fileList">
          <a-upload
              list-type="picture"
              :fileList="fileList"
              @change="upLoad"
              :customRequest="customRequest"
          >
            <a-button> <a-icon type="upload" /> 选择图片 </a-button>
          </a-upload>
          <div style="position: relative;margin-bottom: 10px;height: 120px;width: 250px;border: #D9D9D9 dashed 2px;padding: 4px;" v-for="(item, index) in fileList">
            <img style="width: 100%;height: 100%;float: left" :src="item">
            <span @click="removeImg(index)" style="position: absolute;top: -5px;right: 2px;cursor: pointer">
            <svg t="1710147146919" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4217" width="20" height="20"><path d="M703.488 335.872c-10.24-10.24-27.648-10.24-37.888 0L520.192 481.28 373.76 335.872c-10.24-10.24-27.648-10.24-37.888 0s-10.24 27.648 0 37.888L481.28 520.192 335.872 665.6c-10.24 10.24-10.24 27.648 0 37.888s27.648 10.24 37.888 0l145.408-145.408L665.6 703.488c10.24 10.24 27.648 10.24 37.888 0s10.24-27.648 0-37.888L558.08 520.192l145.408-145.408c11.264-11.264 11.264-28.672 0-38.912z" fill="#d81e06" p-id="4218"></path><path d="M517.12 54.272C262.144 54.272 54.272 262.144 54.272 517.12s207.872 462.848 462.848 462.848 462.848-207.872 462.848-462.848S772.096 54.272 517.12 54.272z m0 872.448c-226.304 0-410.624-184.32-410.624-410.624s184.32-410.624 410.624-410.624 410.624 184.32 410.624 410.624c-1.024 227.328-184.32 410.624-410.624 410.624z" fill="#d81e06" p-id="4219"></path></svg>
          </span>
          </div>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="()=> bannerShow = false">取消</a-button>
        <a-popconfirm
            title="是否确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delBanner"
        >
          <a-button>删除banner</a-button>
        </a-popconfirm>
        <a-button type="primary" @click="bannerOk">确定</a-button>
      </template>
    </a-modal>
    <PublicSample :propList="propList"></PublicSample>
  </div>
</template>

<script>
import {
  delExamType, delExamTypeNew, delModeRemoveApi,
  getExamType, getModeInModeApi, getModeSelectAll, getMoKuaiData,
  getTitleId,
  getTypes,
  postExamType, postExamTypeNew, postModeAddApi,
  putExamOpen,
  putExamType, putExamTypeNew, putModeUpDownApi,
} from "@/service/titleExam_api";
import PublicSample from "@/utils/PublicSample";
import moment from "moment";
import headers from '@/service/api_header_config'
import {update} from "@/utils/update";
export default {
  name: "examType",
  components: {PublicSample},
  data(){
    let validateRange = (rule, value, callback) => { // 自定义校验规则-类型
      if (this.fileList.length > 0) {
        callback();
      }else {
        callback(new Error('请上传至少一张图片'));
      }
    };
    return{
      heards: headers.api_header,
      fileList: [],
      bannerShow: false,
      examTimeShow: false,
      moment,
      editingKey: '',
      propList:{
        visible:false,
        code:null,
      },
      addShow:false,
      editShow:false,
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      addForm:{
        isOpen:0
      },
      editForm:{},
      InfoTable:[],
      titleInfo:[],
      typeInfo:[{
        value:1001,
        label:'医生'
      },
        {
          value:1002,
          label:'护士'
        },
        {
          value:1003,
          label:'医技'
        },
        {
          value:1004,
          label:'药师'
        },
        {
          value:1005,
          label:'医学生'
        },
        {
          value:1006,
          label:'其他医务从业者'
        },
        {
          value:1007,
          label:'非医务从业者'
        },],
      rules:{
        fileList:[{ validator:validateRange,required: true,  trigger: 'blur' }],
        dailyPracticeNum:[{ required: true, message: '请输入', trigger: 'blur' }],
        examTime:[{ required: true, message: '请选择', trigger: 'change' }],
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        memberType:[{ required: true, message: '请选择身份', trigger: 'blur' }],
        memberJobTitle:[{required:true,message:'请输入职称',trigger: 'blur'}],
        // isOpen:[{ required: true, message: '是否开启', trigger: 'change' }]
      },
      labelCol: { span: 5},
      wrapperCol: { span: 16 },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      columns:[
        {
          title: '分类名称',
          dataIndex: 'name',
        },
        {
          title: '关联职称',
          scopedSlots: { customRender: 'examType' }
        },
        {
          title: '身份',
          dataIndex: 'memberType'
        },
        {
          title: '考试时间',
          scopedSlots: { customRender: 'examTime' }
        },
        {
          title: 'Banner图',
          scopedSlots: { customRender: 'banner' }
        },
        {
          title: '设置功能',
          scopedSlots: { customRender: 'openMode' }
        },
        {
          title: '随机题目数',
          scopedSlots: { customRender: 'dailyPracticeNum' }
        },
        // {
        //   title: '默认显示',
        //   scopedSlots: { customRender: 'open' }
        // },
        {
          title: '创建时间',
          dataIndex: 'createdTime'
        },
        {
          title: '默认开启',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' }
        },
      ],
      openModeVlist: false,
      modeColumns: [
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '操作人',
          dataIndex: 'createdByName'
        },
        {
          title: '操作时间',
          dataIndex: 'createdTime'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' }
        }
      ],
      modeTable: [],
      cacheData: [],
      modeSping: false,
      modeAllList: [],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"职称考试分类管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
    // console.log(JSON.parse('[\"住院医师\", \"医师\"]').join(','))
  },
  mounted() {
    this.getTitleExamType()
    // this.getAllModeList()
  },
  methods:{
    modeAddRow () {
      const id = Math.max(...this.modeTable.map(item => item.id)) + 1
      this.modeTable.push({
        editable: true,
        id
      })
      this.editingKey = id
    },
    async getAllModeList (id) {
      const data = {
        typeId: id
      }
      const res = await getModeSelectAll(data)
      if (res.code === 200) {
        this.modeAllList = res.data
      }
    },
    async delMode (id) {
      this.modeSping = true
      const res = await delModeRemoveApi(id)
      if (res.code === 200) {
        this.$message.success('删除成功')
        await this.getModeInModeFunc(this.editForm)
      }else {
        this.$message.warning(res.message)
      }
    },
    async modeUp (id, type) {
      this.modeSping = true
      const res = await putModeUpDownApi(id, type)
      if (res.code === 200) {
        this.$message.success('操作成功')
        await this.getModeInModeFunc(this.editForm)
      } else {
        this.$message.warning(res.message)
      }
    },
    handleChange(value, row) {
      row.funcId = value
      // const newData = [...this.modeTable];
      // const target = newData.find(item => key === item.id);
      // if (target) {
      //   target[column] = value;
      //   this.modeTable = newData;
      // }
    },
    edit(key) {
      const newData = [...this.modeTable];
      const target = newData.find(item => key === item.id);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.modeTable = newData;
      }
    },
    async save(key, row) {
      if (!row.funcId) {
        this.$message.warning('请选择功能')
        return
      }
      this.modeSping = true
      const newData = [...this.modeTable];
      // const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.id);
      const data = {
        typeId: this.editForm.id,
        funcId: row.funcId,
      }
      const res = await postModeAddApi(data)
      if (res.code === 200) {
        this.editingKey = '';
        this.$message.success('保存成功')
        await this.getModeInModeFunc(this.editForm)
      } else {
        this.$message.warning(res.message)
      }
      // return
      // const targetCache = newCacheData.find(item => key === item.id);
      // if (target && targetCache) {
      //   delete target.editable;
      //   this.modeTable = newData;
      //   Object.assign(targetCache, target);
      //   this.cacheData = newCacheData;
      // }
      // this.editingKey = '';
    },
    cancelE(key) {
      const newData = [...this.modeTable];
      const index = newData.findIndex(item => key === item.id);
      this.modeTable.splice(index, 1)
      this.editingKey = '';
      // if (target) {
      //   Object.assign(target, this.cacheData.find(item => key === item.id));
      //   delete target.editable;
      //   this.modeTable = newData;
      // }
    },
    async getModeInModeFunc (item) {
      const res = await getModeInModeApi(item.id)
      if (res.code === 200) {
        this.modeTable = res.data
        this.cacheData = res.data
        this.openModeVlist = true
        this.modeSping = false
      }
    },
    openModeClick (row) {
      this.editForm = {...row}
      this.getModeInModeFunc(row)
    },
    async delBanner () {
      this.editForm.banner = null
      const status = this.InfoTable.some(row => row.id === this.editForm.id && row.banner && row.banner.length > 0)
      if (status) await this.editOk(true)
      else this.bannerShow = false
    },
    async delTime () {
      this.editForm.examTime = null
      const status = this.InfoTable.some(row => row.id === this.editForm.id && row.examTime)
      if (status) await this.editOk(true)
      else this.examTimeShow = false
    },
    dpnClick () {

    },
    bannerClick (row) {
      this.fileList = row.banner ? row.banner : []
      this.editForm = {...row}
      this.bannerShow = true
    },
    removeImg (index) {
      this.fileList.splice(index, 1)
    },
    async customRequest (a) {
      console.log(a)
      let reader = new FileReader() // 读取文件对象
      reader.readAsDataURL(a.file) //读取文件并将文件以URL的形式保存在result属性中 base64格式
      reader.onload = ((e)=> {
        let result = e.target.result // 获取base64格式图片
        const image = new Image() // 创建图片对象
        image.onerror = (e) => {
          this.$message.warning('图片加载失败，请联系管理员')
        }
        image.src = result // 将生成的base64格式作为图片地址
        image.onload = (async () => {
          if (image.width > 1920) {
            this.$message.warning('图片宽度不能超过500')
            return
          }
          if (image.height > 520) {
            this.$message.warning('图片高度不能超过500')
            return
          }
          if (a.file.size > 512000 * 2){
            this.$message.warning('图片太大')
            return
          }
          const image2 = await update(a.file)
          if (image2.code === 0) {
            this.fileList.push(image2.data.url)
            this.$message.success('上传成功')
          }
        })
      })
    },
    upLoad (info) {
      console.log(info)
    },
    bannerOk () {
      this.$refs.bannerRef.validate(async valid => {
        if (valid) {
          this.editForm.banner = [...this.fileList]
          await this.editOk(true)
        } else {
          this.$message.warning('请填写完整表单')
        }
      })
    },
    disabledDate(current) { // 限制日期选择
      return current && current < moment().subtract(1, 'days').endOf('day')
    },
    switchChange (status, row) {
      this.editForm = {...row}
      this.editForm.isOpen = Number(status)
      this.editOk(true)
    },
    timeOpen (e, row) {
      this.editForm = {...row}
      this.examTimeShow = true
      console.log(this.editForm)
    },
    examTimeChange (e) {
      this.editForm.examTime = moment(e).format('YYYY-MM-DD')
    },
    async examTimeOk (row) {
      this.$refs.timeRef.validate(async valid => {
        if (valid) {
          await this.editOk(true)
        } else {
          this.$message.warning('请填写完整表单')
        }
      })
    },
    async getTitleExamType(){
      this.spinning =true
      const data = {
        page: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      const exam = await getMoKuaiData(data)
      if (exam.code === 200){
        this.spinning =false
        this.InfoTable = exam.data
        this.pagination.total = exam.count
      }
    },
    paginationChange(pageNum,pageSize){
      this.pagination.current = pageNum
      this.pagination.pageSize = pageSize
      this.getTitleExamType()
    },
    openWitch(checked){
      if (checked === false){
        this.addForm.isOpen = 0
      }else {
        this.addForm.isOpen = 1
      }
    },
    //选择身份
    async typeChange(type){
      const Info =await getTypes(type)
      this.titleInfo = Info.data.row
    },
    //新增
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const createdByName =  JSON.parse(user_info).user_name
          const data = {
            ...this.addForm,
            createdByName
          }
          const add =await postExamTypeNew(data)
          if (add.code === 200){
            await this.getTitleExamType()
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addForm = {
              isOpen:0
            }
            this.addShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    openSample(code){
      // code加上时间防止重复
      // let time = new Date().getTime() + '';

      this.propList = {
        visible:true,
        code:code+'202303211757',
      }
    },
    editBtn(id,ref, row){
      // const edit =await  getTitleId(id)
      // this.editForm = edit.data
      let arr = JSON.parse(JSON.stringify(ref))
      this.editForm = {
        ...row
      }
      let obj=this.typeInfo.find(function (x) {
        return x.label === arr.memberType
      })
      this.typeChange(obj.value)
      this.editId = id
      this.editShow = true
    },
    openEdit(open){
      this.editForm.isOpen = open
    },
    //修改
    async editOk(status){
      this.$refs.editItem.validate(async valid =>{
        if(status || valid){
          const data = {
            ...this.editForm,
          }
          const edit = await putExamTypeNew(data)
          // switch (this.editForm.isOpen){
          //   case 1 :
          //     await putExamOpen(this.editId)
          // }
          if (edit.code === 200){
            await this.getTitleExamType(this.pagination.current)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
            this.examTimeShow = false
            this.bannerShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除职称考试分类
    async delTitle(id){
      const Info =await delExamTypeNew(id)
      if (Info.code === 200){
        await this.getTitleExamType()
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除')
    },
  },
}
</script>

<style lang="scss" scoped>
.examType{
  margin: -15px -15px;
}
.select_Info{
  display: flex;
  align-items: center;
  height: 60px;
  padding: 15px;
  background-color: white;
}
</style>
